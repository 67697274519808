import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { ThemeProvider } from 'styled-components'

import { client } from './../apollo'
import { defaultTheme, GlobalStyles } from 'src/ui/theme'
import { DimensionsProvider } from 'src/ui/dimensions'

import { SnackContainer } from 'src/shared/features/snack'

type Props = {
  element: JSX.Element
}

export const wrapRootElement = (props: Props) => (
  <ApolloProvider client={client}>
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles>
        <DimensionsProvider>
          {props.element}
          <div id="modal"></div>
          <SnackContainer />
        </DimensionsProvider>
      </GlobalStyles>
    </ThemeProvider>
  </ApolloProvider>
)
